import React from "react";
import { Grid, Typography, Box, Button, CardActionArea, CardMedia, CardContent, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moneyIcon from './money-bag.png';


const useStyles = makeStyles((theme) => ({
    wrapper: {
        border: '1px solid #e8e8e8',
        cursor: "pointer",
        margin: "2",

        "&:hover": {
            boxShadow: "0px 5px 25px rgba(0,0,0,0.1)",
        }
    },
    title: {
        color: '#172554 !important',
        fontWeight: '700 !important',
        textTransform: 'capitalize',
    },
    companyName: {
        fontSize: '15px',
        fontWeight: '600 !important',
        color: '#0e1111',
    },
    empType: {
        backgroundColor: '#000 !important',
        padding: theme.spacing(0.75),
        borderRadius: "5px",
        display: "inline-block",
        fontWeight: 600,
        color: "#fff !important"
    },
    iconImg: {
        width: '15px'
    },
    salary: {
        color: '#666666',
        fontSize: '14px',
        fontWeight: '500'
    },
    location: {
        color: '#666666',
        fontSize: '14px',
        fontWeight: '500'
    }
}));

function JobCard(props) {
    const classes = useStyles();

    return (
        <Grid container spacing={2} mt={2}> 
            <CardActionArea component="a" href={props.job_apply_link} target="_blank" className={classes.wrapper}>
                <CardContent sx={{ p: 2, border: '1px dashed grey', borderRadius: '10px'}}>                    
                    <Box component="section" sx={{ flexGrow: 1, alignItems: 'center' }}>
                        <Grid container spacing={2}>
                            { props.employer_logo ? 
                                (
                                <Grid item xs={2}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 60, objectFit: 'contain', padding: 1 }}
                                        image={ props.employer_logo}
                                        alt={ props.employer_name
                                            ? props.employer_name: ""}
                                    />  </Grid>
                                ) :  ""
                            }

                            <Grid item xs={ props.employer_logo ? (6) : (8) }>
                                <Box sx={{ pb: 1 }}>
                                    <Typography variant="h2" className={classes.title}>
                                        {props.job_title}
                                    </Typography>
                                </Box>
                                
                                <Box sx={{ flexGrow: 1,  alignItems: 'center' }}>
                                    <Typography variant="h6" className={classes.companyName}>
                                        {props.employer_name}
                                    </Typography>  
                                    <Box sx={{ }}>
                                        <Typography variant="p" className={classes.location} sx={{ marginRight: '10px' }}>
                                            {props.job_city ? (<span>{props.job_city}</span>) : ("")} 
                                            {props.job_state ? (<span>, {props.job_state} </span>) : ("")} 
                                        </Typography> 
                                        {/* <Typography variant="p">
                                            {props.job_description ? (<span>, {props.job_description} </span>) : ("")}
                                        </Typography> */}
                                        <Typography variant="p">
                                            {props.years_of_experience ? (<span>{props.years_of_experience}</span>) : ("")} 
                                        </Typography>
                                        <Typography variant="p" className={classes.salary}>
                                            {props.job_min_salary ? (<span> <img src={moneyIcon} className={classes.iconImg} alt="money-icon" />  ${props.job_min_salary}</span>) : ("")} 
                                            {props.job_max_salary ? (<span> - ${props.job_max_salary}</span>) : ("")} 
                                        </Typography>  
                                    </Box>    
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Chip size="small" label={props.job_employment_type ? (<span>{props.job_employment_type} </span>) : ("")}  />
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" title={`View ${props.job_title} for ${props.employer_name} `} className={classes.empType}>Apply</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </CardActionArea>
      </Grid>
    );
}

export default JobCard;